<template>
  <div id="eventDetailPage">
    <!-- Page Header -->
    <el-card class="pageHeader">
      <div slot="header">
        <a @click="$router.push({ name: 'EventList' })" style="cursor: pointer">
          <i class="el-icon-back"></i>
        </a>
        &nbsp;&nbsp;&nbsp;
        <span>Event - {{ eventName }}</span>

        <!-- Time picker -->
        <div class="datePicker">
          <span class="datepickerInFilter">
            <el-select v-model="timingFilter" v-if="timingFilter != ''" size="mini" placeholder="Select">
              <el-option v-for="(value, key) in sessionTimingOptions" :key="key" :label="value" :value="key"> </el-option>
            </el-select>
            <el-date-picker v-if="timingFilter == ''" v-model="dateFilterValues" size="mini" type="datetimerange" :picker-options="datePickerShortcutList" format="dd-MM-yyyy hh:mm:ss A" @change="onFilterDateChange"> </el-date-picker>
          </span>
        </div>
      </div>

      <!-- Loader -->
      <div class="loadingDiv" v-if="fetchingData" v-loading="true" style="min-height: 100px"></div>
      <el-row class="eventHeaderInfo" v-if="!fetchingData">
        <el-col class="borderRight eventInfoColumn" :span="4">
          <!-- // save to dashboard pin -->
          <div class="pinToDashboard" @click="showSelectDashboardPopup('total_events')">
            <el-tooltip class="item" effect="dark" content="Pin to dashboard" placement="left">
              <i class="fa fa-thumb-tack" aria-hidden="true"></i>
            </el-tooltip>
          </div>

          <OneColumnNumberComponent v-bind:data="quickViewEvent"></OneColumnNumberComponent>
        </el-col>
        <el-col class="borderRight eventInfoColumn" :span="4">
          <!-- // save to dashboard pin -->
          <div class="pinToDashboard" @click="showSelectDashboardPopup('event_users')">
            <el-tooltip class="item" effect="dark" content="Pin to dashboard" placement="left">
              <i class="fa fa-thumb-tack" aria-hidden="true"></i>
            </el-tooltip>
          </div>

          <OneColumnNumberComponent v-bind:data="quickViewUser"></OneColumnNumberComponent>
        </el-col>
        <el-col class="eventInfoColumn" :span="16">
          <FourColumnNumberComponent v-bind:data="quickViewDeviceDestribution"></FourColumnNumberComponent>
          <!-- // save to dashboard pin -->
          <div class="pinToDashboard" @click="showSelectDashboardPopup('device_distribution')">
            <el-tooltip class="item" effect="dark" content="Pin to dashboard" placement="left">
              <i class="fa fa-thumb-tack" aria-hidden="true"></i>
            </el-tooltip>
          </div>
        </el-col>
      </el-row>
    </el-card>

    <!-- Tabs -->
    <el-tabs shadow="never" class="mainTab" v-model="selectedTab" @tab-click="onTabChange()">
      <!-- Quick View Tab -->
      <el-tab-pane class="quickViewTab" name="quickView" label="Quick View">
        <!-- Timeline Graph -->
        <el-card class="box-card graphContainerCard timeLineGraphContainer">
          <div slot="header">
            <span>Trend</span>
            <!-- Timing Group -->
            <el-select class="timingGroup" v-model="quickViewDurationGroup" size="mini" placeholder="Select" @change="fetchQuickViewTimelineGraphData()">
              <el-option v-for="item in groupByDurationList" :key="item.value" :label="item.key" :value="item.value"> </el-option>
            </el-select>
          </div>

          <div class="chartContainer">
            <div class="loadingDiv" v-if="fetchingQuickViewTimelineGraphData" v-loading="true"></div>
            <div v-if="!fetchingQuickViewTimelineGraphData">
              <TimelineGraph v-bind:graph="quickViewTimelineGraphData" v-bind:duration="quickViewTimelineGraphData.duration" v-bind:durationGroup="quickViewTimelineGraphData.durationGroup" v-bind:name="'Events'"></TimelineGraph>
            </div>
            <!-- // save to dashboard pin -->
            <div class="pinToDashboard" @click="showSelectDashboardPopup('dynamic_timeline_graph')">
              <el-tooltip class="item" effect="dark" content="Pin to dashboard" placement="left">
                <i class="fa fa-thumb-tack" aria-hidden="true"></i>
              </el-tooltip>
            </div>
          </div>
        </el-card>

        <!-- Property Distribution -->
        <el-card class="box-card graphContainerCard">
          <div slot="header">
            <span>Event Property</span>
            &nbsp;&nbsp;&nbsp;
            <!-- Event Dropdown -->
            <el-select size="mini" v-model="quickViewPropertyGraphProperty" @change="fetchQuickViewPropertyGraphData()">
              <el-option v-for="propertyName in quickViewPropertyList" :key="propertyName" :label="propertyName" :value="propertyName"></el-option>
            </el-select>
          </div>
          <div class="chartContainer">
            <div class="loadingDiv" v-if="fetchingQuickViewPropertyGraphData" v-loading="true"></div>
            <div v-if="!fetchingQuickViewPropertyGraphData">
              <BarGraph v-bind:graph="quickViewPropertyGraphData"></BarGraph>
            </div>
            <!-- // save to dashboard pin -->
            <div class="pinToDashboard" @click="showSelectDashboardPopup('event_property_distribution_bar_graph')">
              <el-tooltip class="item" effect="dark" content="Pin to dashboard" placement="left">
                <i class="fa fa-thumb-tack" aria-hidden="true"></i>
              </el-tooltip>
            </div>
          </div>
        </el-card>
      </el-tab-pane>

      <!-- event list -->
      <el-tab-pane label="Data" name="eventList" class="eventListTab" v-if="!isSystemEvent">
        <div class="card-body eventContainer">
          <!-- Loader Div  -->
          <div class="noDetailsDiv" v-loading="true" v-if="eventDataTab.fetching"></div>

          <!-- Event List -->
          <template v-if="!eventDataTab.fetching && eventDataTab.list != null">
            <el-row class="eventItem" v-for="(event, index) in eventDataTab.list" :key="index">
              <!-- Event name -->
              <el-col :span="12">
                <div class="eventTime">{{ eventName }}</div>
                <div class="customerActions">
                  {{ moment(event.created_at_date).format('Do MMM YYYY hh:mm A') }}
                </div>
              </el-col>

              <!-- Event Details  -->
              <el-col :span="12" class="deviceOsInfo">
                <router-link :to="'/customer/detail?id=' + event.customer_id" target="_blank" style="margin-right: 10px">
                  <el-button type="info" plain size="mini">Customer Details</el-button>
                </router-link>
                <el-popover placement="bottom" width="800" trigger="click">
                  <tree-view :data="getJsonValueIfAvailable(event.eventData)" :options="{ maxDepth: 2, rootObjectKey: 'Event Details' }"></tree-view>
                  <el-button type="info" plain size="mini" slot="reference">Event Details</el-button>
                </el-popover>
              </el-col>
            </el-row>

            <!-- Bottom Pagination -->
            <div v-if="!eventDataTab.fetching && eventDataTab.list.length > 0" class="pagination pagination-bottom" style="border: none">
              <el-pagination @size-change="onEventListPageSizeChange" @current-change="onHandleEventPageChange" :current-page.sync="eventDataTab.pageNo" :page-sizes="[5, 20, 50]" :page-size="eventDataTab.pageSize" layout="sizes, prev, pager, next, jumper" :total="eventDataTab.count"> </el-pagination>
            </div>

            <!-- No Event Div  -->
            <div class="noDetailsDiv" v-if="!eventDataTab.fetching && eventDataTab.list.length == 0">No events found</div>
          </template>
        </div>
      </el-tab-pane>

      <!-- Customer Insights -->
      <!-- <el-tab-pane class="customerTab" name="customer" label="Customer Insights">
        <el-button type="primary" size="large">View All Customers</el-button>
      </el-tab-pane> -->
    </el-tabs>

    <!-- // Dashboard selector popup -->
    <DashboardSelectorComponent ref="dashboardSelector" v-bind:cardType="cardType" v-bind:cardParams="cardParams"> </DashboardSelectorComponent>
  </div>
</template>

<script>
import eventDetailComponent from './eventDetailComponent';
export default eventDetailComponent;
</script>

<style lang="scss" src="./eventDetail.scss"></style>

import eventModule from '../../../services/event';
const moment = require('moment');
import DashboardSelectorComponent from '../../dashboardSelectorPopup/dashboardSelector';
import OneColumnNumberComponent from '@/components/graphs/oneColumnNumber/oneColumnNumber';
import BarGraph from '@/components/graphs/barGraph/barGraph';
import TimelineGraph from '@/components/graphs/dynamicTimelineGraph/dynamicTimelineGraph';
import FourColumnNumberComponent from '@/components/graphs/fourColumnNumber/fourColumnNumber';

export default {
  data() {
    return {
      moment: moment,
      selectedTab: 'quickView',
      timingFilter: '10080',
      dateFilterValues: [],
      durationType: null,
      eventName: null,
      isSystemEvent: true,
      fetchingData: true,

      groupByDurationList: [
        {
          key: 'Hourly',
          value: 'hour'
        },
        {
          key: 'Daily',
          value: 'day'
        },
        {
          key: 'Weekly',
          value: 'week'
        },
        {
          key: 'Monthly',
          value: 'month'
        }
      ],

      // Quick View tab variables
      fetchingQuickViewData: true,
      quickViewEvent: null,
      quickViewUser: null,
      quickViewDeviceDestribution: null,
      quickViewDurationGroup: 'day',
      //Property Graph variables
      quickViewPropertyList: null,
      quickViewPropertyGraphProperty: null,
      fetchingQuickViewPropertyGraphData: true,
      quickViewPropertyGraphData: null,
      quickViewPropertyGraphOptions: null,
      // Timeline graph variables
      fetchingQuickViewTimelineGraphData: true,
      quickViewTimelineGraphData: null,
      quickViewTimelineGraphOptions: null,

      // Event List Tab Variables.
      eventDataTab: {
        pageNo: 1,
        pageSize: 20,
        fetching: false,
        count: null,
        list: null
      },

      //Dashboard selector varibles
      cardType: null,
      cardParams: null,

      quickViewParams: null,
      quickViewGraphParams: null,
      eventPropertyDistributionGraphParams: null,
      sessionInsightsGraphParams: null,
      locationInsightsGraphParams: null,
      technicalInsightsGraphParams: null
    };
  },
  watch: {
    timingFilter: {
      handler: function () {
        if (this.timingFilter) {
          this.groupByDurationList = this.getGroupByDurationList(this.timingFilter);
          this.quickViewDurationGroup = this.groupByDurationList[0].value;
          this.onFilterDateChange();
        }
      }
    }
  },
  components: {
    DashboardSelectorComponent,
    OneColumnNumberComponent,
    BarGraph,
    TimelineGraph,
    FourColumnNumberComponent
  },
  methods: {
    onFilterDateChange() {
      this.fetchEventDetails();
    },
    showSelectDashboardPopup(cardType) {
      this.cardType = cardType;

      if (cardType == 'total_events' || cardType == 'event_users' || cardType == 'device_distribution') {
        this.cardParams = this.quickViewParams;
      } else if (cardType == 'dynamic_timeline_graph') {
        this.cardParams = this.quickViewGraphParams;
      } else if (cardType == 'event_property_distribution_bar_graph') {
        this.cardParams = this.eventPropertyDistributionGraphParams;
      } else if (cardType == 'sessions_per_day_graph' || cardType == 'hourly_avg_event_graph') {
        this.cardParams = this.sessionInsightsGraphParams;
      } else if (cardType == 'city_distribution_graph' || cardType == 'region_distribution_graph' || cardType == 'country_distribution_graph') {
        this.cardParams = this.locationInsightsGraphParams;
      } else if (cardType == 'device_distribution_graph' || cardType == 'operating_system_distribution_graph' || cardType == 'browser_distribution_graph') {
        this.cardParams = this.technicalInsightsGraphParams;
      }

      this.$refs.dashboardSelector.dialogFormVisible = true;
    },
    fetchEventDetails() {
      this.fetchingData = true;

      if (this.timingFilter === '') {
        this.startTime = moment(this.dateFilterValues[0]);
        this.endTime = moment(this.dateFilterValues[1]);
        this.durationType = 'exact';
      } else {
        this.startTime = moment().subtract(this.timingFilter, 'minutes');
        this.endTime = moment();
        this.durationType = 'latest';
      }

      let params = {
        startTime: this.startTime.format('YYYY-MM-DD HH:mm:ss'),
        endTime: this.endTime.format('YYYY-MM-DD HH:mm:ss'),
        eventName: this.eventName,
        durationType: this.durationType,
        duration: this.timingFilter
      };
      this.quickViewParams = params;
      // Read quick view data
      eventModule
        .getEventDetailPageGeneralData(params, this)
        .then((result) => {
          this.quickViewPropertyList = result.data.data.propertyList;
          this.quickViewDeviceDestribution = result.data.data.deviceDestribution;
          this.quickViewEvent = result.data.data.eventCount;
          this.quickViewUser = result.data.data.userCount;

          this.fetchingData = false;

          setTimeout(() => {
            this.onTabChange();
          }, 500);
        })
        .catch((err) => {
          this.reportError(err);
          this.errorToast('Something went wrong.');
          this.fetchingQuickViewData = false;
        });
    },

    onTabChange() {
      console.log('tab changed...', this.selectedTab);
      if (this.selectedTab == 'quickView') {
        this.fetchQuickViewData();
      } else if (this.selectedTab == 'session') {
        this.fetchSessionTabData();
      } else if (this.selectedTab == 'location') {
        this.fetchLocationTabData();
      } else if (this.selectedTab == 'technology') {
        this.fetchTechnologyTabData();
      } else if (this.selectedTab == 'eventList') {
        this.fetchEventListTabData();
      }
    },

    //#region ------------------------ Quick View Tab Methods ------------

    fetchQuickViewData() {
      this.fetchingQuickViewData = true;

      // Fetch timeline data
      this.fetchQuickViewTimelineGraphData();

      // Fetch property distribution data
      this.quickViewPropertyGraphProperty = this.quickViewPropertyList[0];
      this.fetchQuickViewPropertyGraphData();

      this.fetchingQuickViewData = false;
    },

    fetchQuickViewTimelineGraphData() {
      this.fetchingQuickViewTimelineGraphData = true;

      if (this.timingFilter === '') {
        this.startTime = moment(this.dateFilterValues[0]);
        this.endTime = moment(this.dateFilterValues[1]);
        this.durationType = 'exact';
      } else {
        this.startTime = moment().subtract(this.timingFilter, 'minutes');
        this.endTime = moment();
        this.durationType = 'latest';
      }

      //Build params
      let params = {
        startTime: this.startTime.format('YYYY-MM-DD HH:mm:ss'),
        endTime: this.endTime.format('YYYY-MM-DD HH:mm:ss'),
        eventName: this.eventName,
        durationGroup: this.quickViewDurationGroup,
        durationType: this.durationType,
        duration: this.timingFilter
      };

      this.quickViewGraphParams = params;

      // Fetch data
      eventModule
        .getEventTimelineGraphData(params, this)
        .then((result) => {
          this.quickViewTimelineGraphData = {
            data: result.data.data,
            startTime: this.startTime.format('YYYY-MM-DD HH:mm:ss'),
            endTime: this.endTime.format('YYYY-MM-DD HH:mm:ss'),
            durationGroup: this.quickViewDurationGroup,
            durationType: this.durationType,
            duration: this.timingFilter
          };

          this.fetchingQuickViewTimelineGraphData = false;
        })
        .catch((err) => {
          this.reportError(err);
          this.errorToast('Something went wrong.');
          this.fetchingQuickViewTimelineGraphData = false;
        });
    },

    fetchQuickViewPropertyGraphData() {
      this.fetchingQuickViewPropertyGraphData = true;

      //Build params
      if (this.timingFilter === '') {
        this.startTime = moment(this.dateFilterValues[0]);
        this.endTime = moment(this.dateFilterValues[1]);
        this.durationType = 'exact';
      } else {
        this.startTime = moment().subtract(this.timingFilter, 'minutes');
        this.endTime = moment();
        this.durationType = 'latest';
      }

      let params = {
        startTime: this.startTime.format('YYYY-MM-DD HH:mm:ss'),
        endTime: this.endTime.format('YYYY-MM-DD HH:mm:ss'),
        eventName: this.eventName,
        propertyName: this.quickViewPropertyGraphProperty,
        durationGroup: '',
        durationType: this.durationType,
        duration: this.timingFilter
      };

      this.eventPropertyDistributionGraphParams = params;

      // Fetch data
      eventModule
        .getEventPropertyGraphData(params, this)
        .then((result) => {
          this.quickViewPropertyGraphData = {
            data: result.data.data,
            startTime: this.startTime.format('YYYY-MM-DD HH:mm:ss'),
            endTime: this.endTime.format('YYYY-MM-DD HH:mm:ss'),
            durationGroup: '',
            durationType: this.durationType,
            duration: this.timingFilter
          };
          this.fetchingQuickViewPropertyGraphData = false;
        })
        .catch((err) => {
          this.reportError(err);
          this.errorToast('Something went wrong.');
          this.fetchingQuickViewPropertyGraphData = false;
        });
    },

    //#endregion ------------------------ Quick View Tab Methods ------------

    //#region ------------------------ Event List Tab Methods ------------

    fetchEventListTabData() {
      let params = {
        eventName: this.eventName,
        pageNumber: this.eventDataTab.pageNo,
        pageSize: this.eventDataTab.pageSize,
        readTotal: true
      };

      this.eventDataTab.fetching = true;
      eventModule
        .getEventListByEventName(params)
        .then((result) => {
          this.eventDataTab.list = result.data.list;
          for (let i = 0; i < this.eventDataTab.list.length; i++) {
            let eventData = JSON.parse(JSON.stringify(this.eventDataTab.list[i]));
            delete eventData.id;
            delete eventData.session_id;
            delete eventData.customer_id;
            delete eventData.created_at_date;
            this.eventDataTab.list[i].eventData = eventData;
          }

          if (result.data.total) {
            this.eventDataTab.count = result.data.total;
          }
          this.eventDataTab.fetching = false;
        })
        .catch((error) => {
          this.reportError(error);
          this.errorToast('Something went wrong!');
          this.eventDataTab.fetching = false;
        });
    },

    onEventListPageSizeChange(val) {
      this.eventDataTab.pageNo = 1;
      this.eventCount = null;
      this.eventDataTab.pageSize = val;
      this.fetchEventListTabData();
    },
    onHandleEventPageChange(val) {
      this.eventDataTab.pageNo = val;
      this.fetchEventListTabData();
    }

    //#endregion ------------------------ Event List Tab Methods ------------
  },
  created() {
    this.dateFilterValues = [moment().subtract(7, 'days').valueOf(), moment()];
    this.eventName = this.$route.params.eventName;
    this.isSystemEvent = this.grwDefaultEventList.indexOf(this.eventName) >= 0;
    this.timezone = this.$store.state.token.timezone ? this.$store.state.token.timezone : '+5:30';
    this.startTime = moment().subtract(this.timingFilter, 'minutes');
    this.fetchEventDetails();

    // set group by list
    this.groupByDurationList = this.getGroupByDurationList(this.timingFilter);
    this.quickViewDurationGroup = this.groupByDurationList[0].value;
  }
};
